import { useCallback, useEffect, useMemo, useState } from 'react';
import Media from 'react-media';

import { useBasketAddProduct, useGetBasket } from '@api/basket';
import { BasketProduct } from '@api/basket/types';
import { ProductDetail } from '@api/catalog';

import { useAuth } from '@context/auth';
import { OfferCardItem, useCartContext } from '@context/cart';

import ProductStoresPopup from '@views/product/components/ProductStoresPopup/ProductStoresPopup';
import { getStoreCity } from '@views/product/components/helper';

import Counter from '@controls/Counter';

import Loader from '@components/controls/Loader';

import { useAddToBasketHandler } from '@hooks/useAddToBasketHandler';
import { useChangeQty } from '@hooks/useChangeQty';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { PRICE_UNITS, PRICE_UNITS_ENUM_VALUES, VIEW_OPTIONS } from '@scripts/enums';
import { Button, colors, scale } from '@scripts/gds';
import { declOfNum, fromKopecksToRouble } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

const BasketButton = ({
    productItem,
    activeView,
    isInBasket,
}: {
    productItem: ProductDetail;
    activeView?: string;
    isInBasket?: boolean;
}) => {
    const { sm } = useMedia();

    const { isAuthUser } = useAuth();

    const { id, quantity, multiple, offers } = productItem;

    const { basketProducts } = useCartContext();
    const { isLoading: isAddBasketLoading, error } = useBasketAddProduct();
    const { data: apiBasketData, isFetching } = useGetBasket(isAuthUser);

    const [isLoadingBasket, setIsLoadingBasket] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const isUniqueOffer = useMemo(() => productItem.offers?.length === 1, [productItem]);

    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);

    const currentBasketProducts = useMemo(
        () =>
            isAuthUser
                ? basketData.filter(item => offers?.map(offer => offer.id).includes(item.id))
                : basketProducts.filter(item => item.id === id),
        [basketProducts, basketData, isAuthUser]
    );

    const isProductInBasket = currentBasketProducts[0]?.qty > 0;

    const addToBasket = useAddToBasketHandler();

    const addToBasketHandler = useCallback(() => {
        if (!productItem) return;
        const uniqOffer = productItem.offers?.filter(
            offer => offer.quantity >= fromKopecksToRouble(productItem.multiple)
        )[0];

        const storeCity = getStoreCity(uniqOffer!);

        addToBasket(Number(uniqOffer?.id), {
            ...productItem,
            store_city: storeCity,
        }).then(() => {
            if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'move_to_basket');
            }
        });
    }, [basketProducts, basketData, productItem, isAuthUser, id]);

    const changeQtyHelper = useChangeQty(productItem);

    const changeQtyHandler = useCallback(
        (value: number) => {
            changeQtyHelper(value, Number(productItem.offers?.[0]?.id));
        },
        [basketProducts, isAuthUser, id]
    );

    const totalQtyInBasket = (currentBasketProducts as (BasketProduct | OfferCardItem)[]).reduce(
        (acc: number, curr: BasketProduct | OfferCardItem) => acc + curr.qty,
        0
    );

    const isLoading = isAddBasketLoading || isLoadingBasket;

    useEffect(() => {
        if ((!isFetching && isLoadingBasket) || error) {
            setIsLoadingBasket(false);
        }
    }, [isFetching, error]);

    return (
        <div>
            {isProductInBasket && !isUniqueOffer && (
                <div css={{ marginBottom: scale(1, true) }}>
                    <span css={{ color: colors.textSecondGrey }}>В корзине</span>
                    <span>
                        {' '}
                        {totalQtyInBasket}{' '}
                        {currentBasketProducts[0].price_unit === PRICE_UNITS.COIL ? (
                            declOfNum(totalQtyInBasket, ['бухта', 'бухты', 'бухт'])
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: PRICE_UNITS_ENUM_VALUES[currentBasketProducts[0].price_unit],
                                }}
                            />
                        )}
                    </span>
                </div>
            )}

            {isProductInBasket || isInBasket ? (
                <>
                    {isLoading ? (
                        <Loader
                            height={scale(7, true)}
                            width={scale(7, true)}
                            css={{
                                position: 'static',
                                background: 'transparent',
                                width: scale(7, true),
                                height: scale(7, true),
                            }}
                        />
                    ) : isUniqueOffer ? (
                        <Counter
                            isBuyBtn
                            name="qty"
                            label=""
                            value={currentBasketProducts?.[0]?.qty}
                            max={quantity}
                            step={fromKopecksToRouble(multiple)}
                            onChange={changeQtyHandler}
                        />
                    ) : (
                        <div
                            css={{
                                padding: `${scale(1, true)}px 0`,
                                height: scale(6),
                                width: activeView === VIEW_OPTIONS.LINES ? scale(20) : '100%',
                                backgroundColor: colors.primary,
                                borderRadius: 6,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                color: colors.white,
                                [sm]: {
                                    height: scale(5),
                                },
                                alignSelf: activeView === VIEW_OPTIONS.LINES ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <Button
                                data-testid={`buy-button-outlineSecondary`}
                                id={`buy-button-outlineSecondary`}
                                theme={'secondary'}
                                block
                                onClick={() => {
                                    setIsPopup(true);
                                }}
                                size="md"
                                css={{
                                    whiteSpace: 'nowrap',

                                    [sm]: {
                                        height: scale(5),
                                        border: `1px solid ${colors.secondary}`,
                                        color: colors.white,
                                    },
                                    ':hover': {
                                        backgroundColor: `${colors.primary} !important`,
                                        color: `${colors.white} !important`,
                                    },
                                    alignSelf: activeView === VIEW_OPTIONS.LINES ? 'flex-end' : 'flex-start',
                                    width: activeView === VIEW_OPTIONS.LINES ? scale(20) : '100%',
                                }}
                            >
                                Изменить
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <Media query={{ maxWidth: 1023 }} defaultMatches>
                    {matches => (
                        <Button
                            data-testid={`buy-button-outlineSecondary`}
                            id={`buy-button-outlineSecondary`}
                            theme={matches ? 'outlineSecondary' : 'outlineGrey'}
                            block
                            onClick={() => (isUniqueOffer ? addToBasketHandler() : setIsPopup(true))}
                            size="md"
                            css={{
                                whiteSpace: 'nowrap',

                                [sm]: {
                                    height: scale(5),
                                    border: `1px solid ${colors.secondary}`,
                                    color: colors.secondary,
                                },
                                ':hover': {
                                    backgroundColor: `${colors.primary} !important`,
                                    color: `${colors.white} !important`,
                                },
                                alignSelf: activeView === VIEW_OPTIONS.LINES ? 'flex-end' : 'flex-start',
                                width: activeView === VIEW_OPTIONS.LINES ? scale(20) : '100%',
                            }}
                        >
                            {isLoading ? (
                                <Loader
                                    height={scale(7, true)}
                                    width={scale(7, true)}
                                    css={{
                                        position: 'static',
                                        background: 'transparent',
                                        width: scale(7, true),
                                        height: scale(7, true),
                                    }}
                                />
                            ) : (
                                'В корзину'
                            )}
                        </Button>
                    )}
                </Media>
            )}
            <ProductStoresPopup
                isOpen={isPopup}
                closeHandler={() => {
                    setIsPopup(false);
                }}
                product={productItem}
                basketProducts={currentBasketProducts}
            ></ProductStoresPopup>
        </div>
    );
};

export default BasketButton;
