import Link from 'next/link';
import { useEffect, useState } from 'react';

import { ProductImage } from '@api/catalog';

import { HoverGallery } from '@components/HoverGallery';
import Carousel from '@components/controls/Carousel';
import Loader from '@components/controls/Loader';
import Picture from '@components/controls/future/Picture';

import { VIEW_OPTIONS } from '@scripts/enums/products';
import { scale } from '@scripts/gds';
import { transformImageUrl } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';
import { useResize } from '@scripts/hooks/useResize';

const PictureItem = ({
    img,
    link,
    title,
    activeView,
    linkHandler,
}: {
    img?: ProductImage[];
    link: string;
    title: string;
    activeView?: string;
    linkHandler: () => void;
}) => {
    const { sm, md } = useMedia();

    const { isScreenMd } = useResize();

    const [domLoaded, setDomLoaded] = useState(false);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return (
        <>
            {domLoaded ? (
                <div
                    css={{
                        margin:
                            activeView === VIEW_OPTIONS.LINES
                                ? `${scale(3)}px ${scale(7)}px ${scale(3)}px ${scale(3)}px`
                                : '0 auto',
                        marginBottom: scale(3),
                        width: '100%',
                        height: scale(23),
                        maxWidth: scale(23),
                        position: 'relative',
                        zIndex: 0,
                        [md]: {
                            margin: activeView === VIEW_OPTIONS.LINES ? `0 ${scale(5)}px 0 0` : `0 auto ${scale(3)}px`,
                        },
                        [sm]: {
                            margin: activeView === VIEW_OPTIONS.LINES ? `0 ${scale(1)}px 0 0` : `0 auto ${scale(3)}px`,
                            maxWidth: scale(18),
                        },
                    }}
                >
                    {isScreenMd ? (
                        <>
                            {img && img.length > 0 && (
                                <HoverGallery
                                    titleImage={title}
                                    pics={img.sort((a, b) => (a.sort || 0) - (b.sort || 0))}
                                    style={{
                                        width: '100%',
                                        height: '184px',
                                        overflow: 'hidden',
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {img && img.length > 0 && (
                                <Carousel pagination type="productCard" lazy itemsNumber={img.length}>
                                    {img
                                        .sort((a, b) => (a.sort || 0) - (b.sort || 0))
                                        .slice(0, 5)
                                        .map((item, index) => (
                                            <Carousel.Item key={index}>
                                                <Link legacyBehavior href={link} passHref prefetch={false}>
                                                    <a
                                                        aria-label={item.name}
                                                        onClick={linkHandler}
                                                        css={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: scale(23),
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            [sm]: { height: scale(18) },
                                                        }}
                                                    >
                                                        {item.url && (
                                                            <Picture
                                                                sources={[
                                                                    {
                                                                        media: '',
                                                                        image: transformImageUrl(
                                                                            item.url,
                                                                            scale(20),
                                                                            scale(23)
                                                                        ),
                                                                    },
                                                                ]}
                                                                loading={index === 0 ? 'eager' : 'lazy'}
                                                                placeholder="blur"
                                                                alt={title}
                                                                width={scale(20)}
                                                                height={scale(23)}
                                                                style={{
                                                                    height: scale(23),
                                                                    objectFit: 'contain',
                                                                    objectPosition: 'center center',
                                                                }}
                                                            />
                                                        )}
                                                    </a>
                                                </Link>
                                            </Carousel.Item>
                                        ))}
                                </Carousel>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default PictureItem;
